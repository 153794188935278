<template>
  <div>
    <!-- picDBFF25975E8366D248D3658C8F9EFA39.jpg -->
    <img class="rounded img-large" src="">
    <p>
      Around midday on December 3, visitors to the LEGO Imagination Center in Orlando, Florida were surprised to find that one of the mysterious LEGO pods had landed in the store.
    </p>
    <p>
      Several dedicated LEGO fans who had been tracking the pod at <a href="http://www.bradfordrant.org/">www.Bradfordrant.org</a>, were quickly at the scene once news of the landing got out. Allan Nilsson, who travelled all the way from Texas, was the first to say the words ’Pod 2’ to the pod contact person and became the lucky recipient of the pod.
    </p>
  </div>
</template>
